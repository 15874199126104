export function convertDateFormat(stringDate: string) {
  const date = new Date(stringDate);

  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

// function for removing _ of string
export function removeUnderscore(str: string) {
  return str.replace(/_/g, " ");
}

// function to add comma to numbers
export function addCommaToNumber(num: number) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatCurrency(number: number, currency: string, options: Intl.NumberFormatOptions = {}): string {
  // Determine the locale based on the currency
  let locale: string;
  switch (currency) {
    case "USD":
      locale = "en-US";
      break;
    case "CNY":
      locale = "zh-CN";
      break;
    default:
      // This case will never be reached because the currency type is limited to 'USD' | 'CNY'
      throw new Error("Unsupported currency");
  }

  // Create a formatter with the specified currency and any additional options
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    ...options,
  });

  return formatter.format(number);
}

export function formatDateFromString(
  dateString: string | null,
  locale: string = "en-US",
  options: Intl.DateTimeFormatOptions = {},
): string {
  const date = dateString ? new Date(dateString) : new Date(); // Month is 0-indexed in JavaScript Date objects

  // Use Intl.DateTimeFormat to format the date
  const formatter = new Intl.DateTimeFormat(locale, options);
  return formatter.format(date);
}

export function formatNumber(number: number, locale: string = "en-US", options: Intl.NumberFormatOptions = {}): string {
  const formatter = new Intl.NumberFormat(locale, options);
  return formatter.format(number);
}

/**
 * Flattens children from a structured CardsResponse array while excluding specific entries.
 * @param data - Array of CardsResponse data.
 * @param excludeLast - Whether to exclude the last item in the data array.
 * @param excludeChildId - ID of children to exclude from the results.
 * @returns An array of children with added parentIndex, excluding specified children.
 */
export function flattenChildrenWithExclusion(
  data: CardsResponse[],
  excludeLast: boolean = true,
  excludeChildId: string = "Remaining Data",
): GenericType[] {
  if (!Array.isArray(data)) {
    return [];
  }

  const endIndex = excludeLast ? -1 : undefined; // Determine if the last item should be excluded
  return data.slice(0, endIndex).flatMap((item, parentIndex) =>
    item.children
      .filter((child) => child.child_id !== excludeChildId)
      .map((child) => ({
        parentIndex, // Include parent index for back-reference
        ...child, // Spread all properties of the child
      })),
  );
}

/**
 * Merges two sets of children data by prefixing properties with 'first_' or 'second_'.
 * Entries unique to the second set are also included with first set properties set to null.
 * @param firstChildren Array of Child objects from the first dataset.
 * @param secondChildren Array of Child objects from the second dataset.
 * @param thirdChildren Array of Child objects from the second dataset.
 * @returns Merged array of GenericType objects.
 */
export function mergeChildrenData(
  firstChildren: ChildResponse[],
  secondChildren: ChildResponse[],
  thirdChildren: ChildResponse[] = [],
): GenericType[] {
  // Create a map for quick lookup of second children
  const secondChildrenMap = new Map(secondChildren.map((child) => [child.child_id, child]));
  const secondProcessed = new Map<string, boolean>();

  // Create a map for quick lookup of third children if provided
  const thirdChildrenMap = new Map(thirdChildren.map((child) => [child.child_id, child]));
  const thirdProcessed = new Map<string, boolean>();

  const addPrefixToChild = (child: ChildResponse, prefix: string): GenericType => {
    const result: GenericType = { child_id: child.child_id, name: child.name };
    Object.keys(child).forEach((key) => {
      if (key !== "child_id" && key !== "name") {
        result[`${prefix}${key}`] = child[key];
      }
    });
    return result;
  };

  // Combine data from the first set with the possible corresponding entries from the second and third sets
  const output = firstChildren.reduce((acc, child) => {
    const secondChild = secondChildrenMap.get(child.child_id);
    const thirdChild = thirdChildrenMap.get(child.child_id);
    const firstResult = addPrefixToChild(child, "first_");
    const secondResult = secondChild ? addPrefixToChild(secondChild, "second_") : {};
    const thirdResult = thirdChild ? addPrefixToChild(thirdChild, "third_") : {};
    const combinedResult = { ...firstResult, ...secondResult, ...(thirdChildren.length > 0 ? thirdResult : {}) };
    acc.push(combinedResult);
    if (secondChild) {
      secondProcessed.set(secondChild.child_id, true);
    }
    if (thirdChild) {
      thirdProcessed.set(thirdChild.child_id, true);
    }

    return acc;
  }, [] as GenericType[]);

  // Handle children in the second dataset that are not in the first
  return secondChildren.reduce((acc, child) => {
    if (!secondProcessed.has(child.child_id)) {
      const secondResult = addPrefixToChild(child, "second_");
      const thirdResult = thirdChildren.length > 0 ? addPrefixToChild(child, "third_") : {};
      const firstResult = addPrefixToChild({ child_id: child.child_id, name: child.name } as ChildResponse, "first_");
      Object.keys(firstResult).forEach((key) => {
        if (!key.startsWith("child_id") && !key.startsWith("name")) {
          firstResult[key] = null;
        }
      });
      const combinedResult = { ...firstResult, ...secondResult, ...(thirdChildren.length > 0 ? thirdResult : {}) };
      acc.push(combinedResult);
    }
    return acc;
  }, output);
}

/**
 * Restructures data for table display with flattened structure.
 * @param firstChildren Array of Child objects from the first dataset.
 * @param secondChildren Array of Child objects from the second dataset.
 * @param thirdChildren Optional array of Child objects from the third dataset.
 * @returns Flattened data array for table display.
 */
export function restructureDataForTable(
  firstChildren: ChildResponse[],
  secondChildren: ChildResponse[],
  thirdChildren: ChildResponse[] = [],
): GenericType[] {
  const combinedChildren = [...firstChildren, ...secondChildren, ...thirdChildren];
  const groupedData: { [key: string]: GenericType[] } = {};

  const addMissingKeys = (
    child: Partial<ChildResponse>,
    keys: Set<string>,
    defaultValues: boolean = false,
  ): GenericType => {
    const result: GenericType = { ...child };
    keys.forEach((key) => {
      if (key !== "child_id" && key !== "name") {
        if (defaultValues) {
          // Set default values based on the type of the key
          result[key] = typeof result[key] === "number" ? 0 : "";
        } else {
          // Ensure undefined keys are set to default values based on their expected type
          result[key] = child[key] !== undefined ? child[key] : typeof result[key] === "number" ? 0 : "";
        }
      }
    });
    return result;
  };

  const allKeys = new Set<string>();
  combinedChildren.forEach((child) => {
    Object.keys(child).forEach((key) => {
      if (key !== "child_id" && key !== "name") {
        allKeys.add(key);
      }
    });
  });

  firstChildren.forEach((child) => {
    if (!groupedData[child.child_id]) {
      groupedData[child.child_id] = [];
    }
    groupedData[child.child_id].push(addMissingKeys(child, allKeys));
    const secondElement = secondChildren.find((c) => c.child_id === child.child_id);
    if (!secondElement) {
      groupedData[child.child_id].push(addMissingKeys({ child_id: child.child_id, name: child.name }, allKeys, true));
    }
  });

  secondChildren.forEach((child) => {
    if (!groupedData[child.child_id]) {
      groupedData[child.child_id] = [];
      groupedData[child.child_id].push(addMissingKeys({ child_id: child.child_id, name: child.name }, allKeys, true));
    }
    groupedData[child.child_id].push(addMissingKeys(child, allKeys));
  });

  thirdChildren.forEach((child) => {
    if (!groupedData[child.child_id]) {
      groupedData[child.child_id] = [];
    }
    groupedData[child.child_id].push(addMissingKeys(child, allKeys));
  });

  const output: GenericType[] = [];
  Object.keys(groupedData).forEach((child_id) => {
    const children = groupedData[child_id];

    // Ensure there are exactly two rows per parent row
    while (children.length < 2) {
      const emptyRow: GenericType = { child_id, name: children[0].name };
      allKeys.forEach((key) => {
        emptyRow[key] = typeof children[0][key] === "number" ? 0 : "";
      });
      children.push(emptyRow);
    }

    output.push(...children);
  });

  return output;
}

export function groupObjectsBy<T>(array: T[], key: keyof T): { [key: string]: T[] } {
  return array.reduce(
    (result, currentValue) => {
      const groupKey = String(currentValue[key]);
      (result[groupKey] = result[groupKey] || []).push(currentValue);
      return result;
    },
    {} as { [key: string]: T[] },
  );
}

// Function to calculate Click-Through Rate (CTR)
export function calculateCTR(clicks: number, impressions: number): number {
  return (clicks / impressions) * 100;
}

// Function to calculate Cost Per Mille (CPM)
export function calculateCPM(cost: number, impressions: number): number {
  return (cost / impressions) * 1000;
}

// Function to calculate Cost Per Click (CPC)
export function calculateCPC(cost: number, clicks: number): number {
  return cost / clicks;
}

export function getPlatformDisplayName(platform: string): string {
  switch (platform) {
    case "mfw":
      return "Mafwengo";
    case "ups":
      return "UPS";
    case "ctrip":
      return "Ctrip";
    case "ipinyou":
      return "iPinYou";
    case "wechat":
      return "WeChat";
    case "tencent":
      return "Tencent";
    case "qunar":
      return "Qunar";
    case "dianping":
      return "Dianping";
    default:
      return platform;
  }
}

export function handleReadFileContent(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(reader.error);
    reader.readAsText(file);
  });
}

export function handleReadFirstChunk(
  file: File,
  chunkSize: number = 1024 * 512, // Default to 1MB
): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const firstChunk = file.slice(0, chunkSize);
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = () => reject(reader.error);
    reader.readAsText(firstChunk);
  });
}

// Helper function: Damerau-Levenshtein distance
export function damerauLevenshtein(a: string, b: string): number {
  const m = a.length;
  const n = b.length;
  const dp = Array.from(Array(m + 1), () => Array(n + 1).fill(0));
  for (let i = 0; i <= m; i++) dp[i][0] = i;
  for (let j = 0; j <= n; j++) dp[0][j] = j;
  for (let i = 1; i <= m; i++) {
    for (let j = 1; j <= n; j++) {
      const cost = a[i - 1] === b[j - 1] ? 0 : 1;
      dp[i][j] = Math.min(dp[i - 1][j] + 1, dp[i][j - 1] + 1, dp[i - 1][j - 1] + cost);

      // Handle transposition
      if (i > 1 && j > 1 && a[i - 1] === b[j - 2] && a[i - 2] === b[j - 1]) {
        dp[i][j] = Math.min(dp[i][j], dp[i - 2][j - 2] + 1);
      }
    }
  }
  return dp[m][n];
}

// Tokenized match function with substring and length proximity check
export function tokenizedMatchWithSubstring(a: string, b: string): boolean {
  const lowerA = a.toLowerCase();
  const lowerB = b.toLowerCase();
  // First check for substring containment
  if (lowerA.includes(lowerB) || lowerB.includes(lowerA)) {
    return true;
  }
  // Second check: ensure length proximity (length difference should not be too large)
  const lengthDifference = Math.abs(a.length - b.length);
  if (lengthDifference > 5) {
    // Set a reasonable threshold for length differences
    return false; // Reject if lengths are too far apart
  }
  // Third check: use Damerau-Levenshtein for further flexible matching
  const distance = damerauLevenshtein(a, b);
  return distance <= 3; // Allow a flexible distance threshold (e.g., 3)
}

// Updated mapCsvHeadersToTemplate function with threshold
export function mapCsvHeadersToTemplate(
  csvHeaders: { originalValue: string; value: string; error: boolean }[],
  templateHeaders: string[] | undefined,
  threshold: number = 3, // Default threshold for Damerau-Levenshtein distance
): {
  updatedCsvHeaders: { originalValue: string; value: string; error: boolean }[];
} {
  const matchedTemplateHeaders = new Set<string>(); // Track already matched template headers
  const updatedCsvHeaders = csvHeaders.map((header) => {
    if (!templateHeaders || templateHeaders.length === 0) {
      return { ...header, error: true };
    }
    // Try to find a match using tokenized match with substring
    let closestMatch = templateHeaders.find(
      (templateHeader) =>
        !matchedTemplateHeaders.has(templateHeader) && tokenizedMatchWithSubstring(header.value, templateHeader),
    );
    // If a match is found, check if it has already been assigned
    if (closestMatch) {
      matchedTemplateHeaders.add(closestMatch); // Mark the template header as used
      return { originalValue: header.originalValue, value: closestMatch, error: false };
    }
    // Fallback: use Damerau-Levenshtein distance if no substring match
    closestMatch = templateHeaders[0];
    let minDistance = damerauLevenshtein(header.value, closestMatch);
    for (const templateHeader of templateHeaders) {
      if (!matchedTemplateHeaders.has(templateHeader)) {
        const distance = damerauLevenshtein(header.value, templateHeader);
        if (distance < minDistance) {
          minDistance = distance;
          closestMatch = templateHeader;
        }
      }
    }
    // Check if the minimum distance is within the threshold
    if (minDistance <= threshold) {
      matchedTemplateHeaders.add(closestMatch); // Mark the template header as used
      return { originalValue: header.originalValue, value: closestMatch, error: false };
    } else {
      // If not within the threshold, return the original CSV header with an error
      return { ...header, error: true };
    }
  });
  return { updatedCsvHeaders };
}

export function formatBytes(bytes: number | null, decimals = 1): string {
  if (bytes === null) return "";
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return `${size}${sizes[i].toLowerCase()}`;
}

export function getExcelColumnLetters(n: number): string[] {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  const result: string[] = [];

  for (let i = 0; i < n; i++) {
    let letters = "";
    let index = i;

    while (index >= 0) {
      letters = alphabet[index % 26] + letters;
      index = Math.floor(index / 26) - 1;
    }

    result.push(letters);
  }

  return result;
}
